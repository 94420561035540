import { TypeName } from './page-slot'

export class FaqModel extends TypeName {
  constructor(item = null) {
         super(item);
      this.topics = item.topics;
      this.faq = item.faq;
      this.answer = item.answer;
      this.__typename = "item.__typename";
    
  }
}

